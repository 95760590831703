import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './assets/css/main.css';
import { ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import AppRoutes from './AppRoutes';
import { theme } from './Theme';
import Loader from './Utils/Loader';
import * as sdkclaro from "@claro/sdkclaro";
import { BrowserRouter as Router, useLocation, useSearchParams } from 'react-router-dom';
import SdkclaroProvider from './Context/SdkclaroProvider';
import Header from 'Utils/Header';

const Site = () => {
  const userIsLogged = localStorage.getItem('ltkn') !== null;
  const [loading, setLoading] = useState(true);


  return (
    <SdkclaroProvider>
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3}>
        {/* <Loader loading={loading} /> */}

        <div id='structure'>
          <Header />
          <div id="main_content">
              <AppRoutes setLoading={setLoading}/>
          </div>
        </div>
      </SnackbarProvider>
    </ThemeProvider>
    </SdkclaroProvider>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  
    <Router>
      <Site />
    </Router>
  
);
