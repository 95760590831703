import { useEffect } from "react";
import Banner from "./Utils/Banner";
import BrandSlider from "./Utils/BrandSlider";
import CategoriesSlider from "./Utils/CategoriesSlider";

export default function Home(props:any) {

    const { setLoading } = props;
    

    return (
        <div className="content">
            <Banner />
            <CategoriesSlider />
            <BrandSlider />
        </div>
    );
}