import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import axios from 'axios';
import { ProjectConfig } from 'Global';
import { ApiRequest } from 'GlobalFunctions';
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import SdkclaroContext from "Context/SdkclaroContext";

export default function OrderStatus(props: any) {
  const { sdkclaroInstance, idOpen, profileInformation } = useContext(SdkclaroContext);

  const navigate = useNavigate();
  const location = useLocation();
  const { 
    id, 
    parent = false 
  } = location.state || {};

  

  const [data, setData] = useState<any>({
    info: {
      nombre_marca: "",
      marca_id: "",
      fecha: "",
      producto: "",
      cantidad: "",
      promocion: "",
      unitario: "",
      costo: "",
      comision: "",
      costo_final: ""
    },
    folios: [
      {
        id: "",
        folio: "",
        barcode: ""
      }
    ],
    link: ""
  });

  console.log("ORDER STATUS", data);
  console.log("ORDER STATUS ID", id);
    
  const loadData = () => {
      let body = new FormData();
      body.append('username', profileInformation.username);

      ApiRequest({
        url: '/v2/historial/' + id,
        method: 'post',
        formData: true,
        body,
        setResponse: (response: any) => {
          console.log('ORDER DETAIL', response.data);
          setData(response.data);
        },
      });

    };

    useEffect(() => {
      console.log("USE EFFECT");
        loadData();
    }, []);
    

    const nombre = "Nombre";
    const folio = "Folio";

    const returnHome = () => {
      navigate(!parent ? -1 : -2);
    }

    const downloadPDF = () => {
      sdkclaroInstance.getTopic("EXECUTE_Minimal_View", {
        typeOperation: "openUrl",
        parameters: data.link
      });
    }



    return (
        <div id='details_container' className="content">
          <div className="details_container_box">
            <VerifiedOutlinedIcon fontSize="large" />
            <h1>Orden procesada con éxito</h1>
            <p className="text-gray-400 text-center">{decodeURIComponent(escape(data.info.producto))}</p>
            <p className="text-gray-400 text-center">{data.folios[0].folio}</p>



            {data.link !== "" && (
              <div className="product_buttons">
                <div className="product_button" onClick={() => {
                  returnHome();
                }}>
                  <div> 
                    <span>Regresar</span>
                  </div>
                </div>

                <a className="product_button pdf_button" style={{ textDecoration: 'none' }}
                  onClick={() => {
                    downloadPDF();
                  }} 
                >
                  <div> 
                    <span>Descargar PDF</span>
                  </div>
                </a>
              </div>
            )}
          </div>
      </div>
    )
}