import { ApiRequest } from './GlobalFunctions';
import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate,
} from 'react-router-dom';
import { Fragment, useEffect, useState } from 'react';
import States from './Components/States/States';
import Home from './Components/Home/Home';
import ProductList from './Components/Products/ProductList';
import ProductDetail from 'Components/Products/ProductDetail';
import OrderStatus from 'Components/Orders/OrderStatus';
import OrdersList from 'Components/Orders/OrdersList';
import OrderError from 'Components/Orders/OrdersError';

export default function AppRoutes(props: any) {
	const { setLoading } = props;

	return (
		<>
			<Routes>
				<Route path="/" element={<States setLoading={setLoading} />} />
				<Route
					path="/state/:state"
					element={<Home setLoading={setLoading} />}
				/>
				<Route
					path="/products/:type/:id"
					element={<ProductList setLoading={setLoading} />}
				/>
				<Route
					path="/product/detail"
					element={<ProductDetail setLoading={setLoading} />}
				/>
				<Route
					path="/orders"
					element={<OrdersList setLoading={setLoading} />}
				/>
				<Route
					path="/orders/status"
					element={<OrderStatus setLoading={setLoading} />}
				/>
				<Route
					path="/orders/error"
					element={<OrderError setLoading={setLoading} />}
				/>
			</Routes>
		</>
	);
}
