import { ProjectConfig } from 'Global';
import { ApiRequest } from 'GlobalFunctions';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';


export default function BrandSlider(props: any) {

    const navigate = useNavigate();

    const handleSelectCategory = (id: string, name: string) => {
        console.log(id, name);
    }


    const [data, setData] = useState<any>([]);
    const loadData = () => {
        const state_id = localStorage.getItem('state');

        ApiRequest({
            url: `/v2/estados/${state_id}/marcas-detail`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            setResponse: (response: any) => {
                setData(response);
            }
        });
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
      <>
        {data.map((item: any, index: any) => (
          <div className="category_carousel" key={index}>
            <h2>{item.nombre}</h2>
            <CarouselProvider
              naturalSlideWidth={100}
              naturalSlideHeight={100}
              totalSlides={item.marcas.length}
              visibleSlides={4}
            >
              <Slider className="slider_container">
                
                {item?.marcas.map((marca: any, i: any) => (
                  <Slide index={i} key={i} onClick={() => {
                    navigate(`/products/brand/${marca.id}`);
                  }}>
                    <div className="icon_container">
                      <img
                        className="category_icon"
                        style={{ backgroundColor: marca.color }}
                        src={ProjectConfig.images_url + "/" + marca.img_path}
                        alt={marca.nombre_marca}
                      />
                    </div>
                  </Slide>
                ))}
              </Slider>
            </CarouselProvider>
          </div>
        ))}
      </>
    );
}