import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import HistoryIcon from '@mui/icons-material/History';
import logo from '../assets/img/logo.png';
import { Link } from 'react-router-dom';

export default function Header() {
    return (
        <div id="topBar">
            <div></div>

            <img src={logo} alt="Logo" width={100} />

            {/* Envolver el icono dentro de un Link */}
            <Link to="/orders" style={{ color: "#CECAB8" }}>
                <HistoryIcon />
            </Link>
        </div>
    );
}
