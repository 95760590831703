import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useLocation, useNavigate } from 'react-router-dom';

export default function OrderError(props: any) {

  const navigate = useNavigate();
  const location = useLocation();
  const { id } = location.state || {};

  const returnHome = () => {
    navigate(-1);
  }

    return (
        <div id='details_container' className="content">
          <div className="details_container_box">
            <ErrorOutlineIcon fontSize="large" />
            <h1>Una disculpa, no tenemos folios disponibles del producto</h1>

            <div className="product_buttons">
              <div className="product_button" onClick={() => {
                returnHome();
              }}>
                <div> 
                  <span>Regresar</span>
                </div>
              </div>

            </div>
            
          </div>
      </div>
    )
}