// SdkclaroProvider.tsx
import React, { useEffect, useState } from 'react';
import SdkclaroContext from './SdkclaroContext';
import * as sdkclaro from '@claro/sdkclaro';
import { useNavigate } from 'react-router-dom';
import { ProjectConfig } from 'Global';
import axios from 'axios';
import { ApiRequest } from 'GlobalFunctions';

//import to use usenavi

interface SdkclaroProviderProps {
	children: React.ReactNode;
}

const SdkclaroProvider: React.FC<SdkclaroProviderProps> = ({ children }) => {
	const [sdkclaroInstance, setSdkclaroInstance] = useState<any>(null);
	const [idOpen, setIdOpen] = useState<any>(null);
	const [profileInformation, setProfileInformation] = useState<any>(null);

	const [dataEventInformation, setDataEventInformation] = useState<any>({
		data: {
			transactionNumber: null,
		},
	});

	const navigate = useNavigate();

	function goBackButton() {
		navigate(-1);
	}

	useEffect(() => {
		const instance = sdkclaro.getInstance(
			'Cuponera',
			(
				apiKeySource: any,
				token: any,
				profileInformation: any,
				resourceInformation: any
			) => {
				console.log('onLaunch');
			},
			(eventName: any, eventInformation: any) => {
				console.log('onShow');
			},
			() => {
				console.log('onHide');
			},
			() => {
				console.log('onError');
			},
			(eventName: any, eventInformation: any) => {
				console.log(`${eventName} log eventName`);

				/*====================================
        BOOT INFORMATION - FIRST EVENT
        ====================================*/
				if (eventName === 'sendBootInformation') {
					console.log('Log sendBootInformation', eventInformation);
					setIdOpen(eventInformation.profileInformation.idOpen);
					setProfileInformation((prevProfileInformation: any) => ({
						...prevProfileInformation,
						...eventInformation.profileInformation,
					}));
				}

				/*====================================
        ON BACK
        ====================================*/
				if (eventName === '_ONBACK_') {
					goBackButton();
				}

				/*====================================
        OTP RESPONSE
        ====================================*/
				if (eventName === 'otp_response') {
					console.log('otp_response', eventInformation);
				}

				/*====================================
        RESPONSE RECHARGE
        ====================================*/
				if (eventName === 'responseRecharge') {
					setDataEventInformation(eventInformation);
				}
			}
		);

		setSdkclaroInstance(instance);

		instance.getTopic(
			'getProfile',
			'',
			(result: any) => {
				console.log('GET EXTRA PROFILE INFORMATION: ', result);
				setProfileInformation((prevProfileInformation: any) => ({
					...prevProfileInformation,
					...result,
				}));
			},
			(error: any) => {
				console.log('getTopicResult error: ', error);
			}
		);

		instance.switchGoBackButton(true);
	}, []);

	/*====================================
  PURCHASE
  ====================================*/
	const createOrder = (eventInformation: any) => {
		console.log(eventInformation, 'Log responseRecharge');
		console.log(
			'RESPONSE RECHARGE profileInformation',
			localStorage.getItem('profileInformation')
		);

		const profileInformation = JSON.parse(
			localStorage.getItem('profileInformation') || '{}'
		);

		/*INSERT ORDER
    ==============================*/
		let body = new FormData();
		body.append('product_id', eventInformation.data.cardNumber);
		body.append('quantity', '1');
		body.append('estado', localStorage.getItem('state') || '1');
		body.append('username', profileInformation.username);
		body.append('name', profileInformation.name);
		body.append(
			'claro-profile.telecom.Mobile',
			profileInformation['claro-profile.telecom.Mobile']
		);
		body.append('email', profileInformation.email);
		body.append('fathersName', profileInformation.fathersName);

		console.log('BODY', body);

		ApiRequest({
			url: '/compra',
			method: 'post',
			formData: true,
			body: body,
			setResponse: (response: any) => {
				console.log('RESPONSE COMPRA', response);

				if (response.state === '500') {
					navigate('/orders/error');
					return;
				} else {
					navigate('/orders/status', {
						state: {
							id: response.productos[0].id,
						},
					});
				}
			},
		});
	};

	useEffect(() => {
		if (dataEventInformation.data.transactionNumber) {
			console.log(
				'DATA EVENT INFORMATION **************',
				dataEventInformation
			);
			createOrder(dataEventInformation);
		}
	}, [dataEventInformation.data.transactionNumber]);

	if (!sdkclaroInstance) {
		return null; // Or a loading indicator
	}

	const contextValue = {
		sdkclaroInstance,
		idOpen,
		profileInformation,
	};

	return (
		<SdkclaroContext.Provider value={contextValue}>
			{children}
		</SdkclaroContext.Provider>
	);
};

export default SdkclaroProvider;
