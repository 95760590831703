import { useNavigate, useParams } from 'react-router-dom';
import ProductCard from './ProductCard';
import { Fragment } from 'react/jsx-runtime';
import { ApiRequest } from 'GlobalFunctions';
import { useEffect, useState } from 'react';

export default function Categories(props: any) {
	const { type, id } = useParams();

	const [data, setData] = useState<any>([]);

	const loadData = () => {
		const state_id = localStorage.getItem('state');

		const url =
			type === 'category'
				? `/v2/categorias/${id}/promociones?estado=${state_id}`
				: `/v2/marcas/${id}/promociones?estado=${state_id}`;

		ApiRequest({
			url: url,
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
			setResponse: (response: any) => {
				setData(response);
			},
		});
	};

	useEffect(() => {
		loadData();
	}, []);

	const navigate = useNavigate();

	return (
		<div className="content_wrapper">
			<div className="content_wrapper_container">
				{data.length === 0 ? (
					<div className="empty_message">Categoría sin productos</div>
				) : (
					data.map((item: any) =>
						item.productos.map((product: any, i: any) => (
							<Fragment key={product.id}>
								<ProductCard
									name={product.nombre}
									price={product.precio}
									image={product.img}
									id={product.id}
									item={item}
								/>
							</Fragment>
						))
					)
				)}
			</div>
		</div>
	);
}
