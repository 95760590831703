import axios from 'axios';
import { ProjectConfig } from 'Global';

/*=========================================================
API REQUEST
=========================================================*/
export const ApiRequest = async (params: any) => {
    
    //if localStorage.setItem('ltkn', response.ltkn); exists send as header
    const ltkn = localStorage.getItem('ltkn');
    
    if (ltkn) {
        params.headers = {
            ...params.headers,
            'ltkn': ltkn
        }
    }
    
    const store_id = localStorage.getItem('store_id');
    if (store_id) {
        params.headers = {
            ...params.headers,
            'store_id': store_id
        }
    }

    const {
        url, 
        method, 
        headers, 
        body = null,
        query = null,
        formData = false,
        setResponse,
    } = params;

    // If formData is true, ensure we use FormData and set the correct header
    let data: any = null;
    if (formData && body instanceof FormData) {
        data = body;
        params.headers = {
            ...params.headers,
            'Content-Type': 'multipart/form-data'
        };
    } else {
        data = body ? body : null;
    }

    const request_params = {
        url: ProjectConfig.api_url + url,
        method: method,
        headers: {
            Authorization: `Bearer ${ProjectConfig.api_token}`,
            ...headers
        },
        params: {
            ...query
        },
        data
    };

    /*REQUEST
    =========================================================*/
    await axios.request(request_params)
        .then((response) => {
            console.log(`${url} response`, response.data);
            setResponse(response.data);
        })
        .catch((err) => {
            console.log("error", err);
        })
        .finally(() => {
            // Any final actions can go here
        });
};



